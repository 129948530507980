<template>
  <div class="Suivi-paiement-th">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-63-px">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  ">
            <span class="title-tabel">Organsime</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  "
            v-model="filterOrganisme"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetOrganismeTh
            ]"
            @change="handleChangeOrganisme"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  "
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-63-px">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  "
            v-model="filterDepot"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedDepots(filterMonth, null, null)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>
        <div v-if="getChargementSuiviPaiementTh" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="error-message ml-3 ">
          <div v-if="getErrorSuiviPaiementTh" class="error">
            <ul v-if="Array.isArray(getErrorSuiviPaiementTh)" class="mb-0">
              <li v-for="(e, index) in getErrorSuiviPaiementTh" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorSuiviPaiementTh }}</div>
          </div>
        </div>

        <div class="box-end-position">
          <b-button
            v-if="cantExport($options.name)"
            size="sm"
            class="button-export-style  ml-2"
            @click="exportFiles"
            >Export</b-button
          >
        </div>
      </div>
    </div>
    <div class="body-Suivi-paiement-th body-box-rapport">
      <div class="table-rapport-style w-100-p">
        <b-table
          responsive
          show-empty
          id="my-table"
          class="custom-table-style tableSuiviPaiementTh  table-header "
          :items="computedgetuserSalary"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(color)> </template>
          <template v-slot:cell(numero_dossier)="data">
            {{ data.item.numero_dossier }}
          </template>
          <template v-slot:cell(etat_de_paiement)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="etat_de_paiement"
              editableType="select"
              :optionsSelect="[
                { full_name: 'encore de paiement' },
                { full_name: 'paye' }
              ]"
              :item="data.item"
              :value="data.item.etat_de_paiement"
              type="text"
              :updateFunction="updateSuiviPaiementTh"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(montant_surprime_cee)="data">
            <EditableInputSuiviTh
              :editable="computedCantUpdate"
              champName="surprime_cee"
              :item="data.item"
              :value="data.item.surprime_cee"
              type="text"
              :updateFunction="updateSuiviPaiementTh"
              valueToShow="montant_surprime_cee"
            />
          </template>

          <template v-slot:cell(prenom1)="data">
            {{ data.item.prenom1 }}
          </template>

          <template v-slot:cell(nom1)="data">
            {{ data.item.nom1 }}
          </template>
          <template v-slot:cell(coef_prime_cee)="data">
            {{ data.item.prime_cee }}
          </template>
          <template v-slot:cell(montant_prime_cee)="data">
            <EditableInputSuiviTh
              :editable="computedCantUpdate"
              champName="montant_prime_cee"
              :item="data.item"
              :value="data.item.montant_prime_cee_origin"
              type="text"
              :updateFunction="updateSuiviPaiementTh"
              valueToShow="montant_prime_cee"
            />
          </template>
          <template v-slot:cell(kwh_cumac)="data">
            <EditableInputSuiviTh
              :editable="computedCantUpdate"
              champName="kwh_cumac"
              :item="data.item"
              :value="data.item.kwh_cumac_origin"
              type="text"
              :updateFunction="updateSuiviPaiementTh"
              valueToShow="kwh_cumac"
            />
          </template>
          <template v-slot:cell(montant_commissions_cee)="data">
            <EditableInputSuiviTh
              :editable="computedCantUpdate"
              champName="commissions_cee"
              :item="data.item"
              :value="data.item.commissions_cee"
              type="text"
              :updateFunction="updateSuiviPaiementTh"
              valueToShow="montant_commissions_cee"
            />
          </template>

          <!-- <template v-slot:cell(numero_facture_prime)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="numero_facture_prime"
              :item="data.item"
              :value="data.item.numero_facture_prime"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviPaiementTh"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(numero_facture_surprime)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="numero_facture_surprime"
              :item="data.item"
              :value="data.item.numero_facture_surprime"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviPaiementTh"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(numero_facture_commissions)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="numero_facture_commissions"
              :item="data.item"
              :value="data.item.numero_facture_commissions"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviPaiementTh"
              :defaultColor="true"
            />
          </template> -->
        </b-table>

        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowSuiviPaiementTh"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditableInput from '../../component/EditableInput.vue';
import EditableInputSuiviTh from '../../component/EditableInputSuiviTh.vue';

import moment from 'moment';
export default {
  name: 'Suivi-des-paiement-Obligé',
  data() {
    return {
      filterMonth: null,
      filteryear: null,
      filterDepot: null,
      filterOrganisme: null,
      ListYear: [],
      page: 1,
      perPage: 20,
      fields: [
        {
          key: 'numero_dossier',
          label: 'Numéro dossier',
          thClass: 'th-1-bg-color th-border-raduis-left'
        },
        { key: 'nom1', label: 'Nom 1', thClass: 'th-1-bg-color' },
        { key: 'prenom1', label: 'Prenom 1', thClass: 'th-1-bg-color' },
        {
          key: 'montant_prime_cee',
          label: 'Montant Prime CEE VERTIGO',
          thClass: 'th-4-bg-color'
        },
        {
          key: 'coef_prime_cee',
          label: 'COEF Prime CEE VERTIGO',
          thClass: 'th-4-bg-color'
        },
        {
          key: 'montant_surprime_cee',
          label: 'Montant Surprime CEE VERTIGO',
          thClass: 'th-4-bg-color'
        },
        {
          key: 'montant_commissions_cee',
          label: 'Montant Commissions CEE VERTIGO',
          thClass: 'th-4-bg-color'
        },
        {
          key: 'kwh_cumac',
          label: 'Kwh cumac',
          thClass: 'th-1-bg-color'
        },
        // {
        //   key: 'numero_facture_prime',
        //   label: 'Numéro facture PrimeVERTIGO',
        //   thClass: 'th-4-bg-color'
        // },
        // {
        //   key: 'numero_facture_surprime',
        //   label: 'Numéro facture SurprimeVERTIGO ',
        //   thClass: 'th-4-bg-color'
        // },
        // {
        //   key: 'numero_facture_commissions',
        //   label: 'Numéro facture CommissionVERTIGO ',
        //   thClass: 'th-4-bg-color'
        // },
        {
          key: 'etat_de_paiement',
          label: 'Etat de paiement ',
          thClass: 'th-4-bg-color  th-border-raduis-rigth'
        }
      ],
      perPageList: [
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getSuiviPaiementThFiltred',
      'updateSuiviPaiementTh',
      'exportExcelSuiviPaiementTh',
      'fetchMonthForYearTH',
      'fetchAllOrganismeTh',
      'featchDepotForYearTH'
    ]),
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.error == true) {
        return 'ligneAnomalieObligee';
      }
      return 'ligneEnCourDeValidation';
    },
    handleChangeMonth() {
      this.page = 1;
      this.filterDepot = null;
      this.handleFilter();
      this.setLocalStorageSuiviPaiementobligee();
    },
    handleChangeDepot() {
      this.page = 1;
      this.handleFilter();
      this.setLocalStorageSuiviPaiementobligee();
    },
    handleChangeOrganisme() {
      this.handleFilter();
      this.setLocalStorageSuiviPaiementobligee();
    },
    handleChangeYear() {
      this.page = 1;
      this.filterMonth = null;
      this.filterDepot = null;
      this.featchDepotForYearTH(this.filteryear);
      this.fetchMonthForYearTH(this.filteryear);
      this.handleFilter();
      this.setLocalStorageSuiviPaiementobligee();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.setLocalStorageSuiviPaiementobligee();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.setLocalStorageSuiviPaiementobligee();
    },
    handleFilter() {
      this.getSuiviPaiementThFiltred({
        depot: this.filterDepot,
        year: this.filteryear,
        page: this.page,
        perPage: this.perPage,
        month: this.filterMonth,
        organisme: this.filterOrganisme
      });
      this.setLocalStorageSuiviPaiementobligee();
    },
    setLocalStorageSuiviPaiementobligee() {
      sessionStorage.setItem('page-suivi-paiement-obligee', this.page);
      localStorage.setItem(
        'suivi-paiement-obligee',
        JSON.stringify({
          year: this.filteryear,
          month: this.filterMonth,
          organisme: this.filterOrganisme,
          depot: this.filterDepot
        })
      );
    },
    async exportFiles() {
      await this.exportExcelSuiviPaiementTh({
        depot: this.filterDepot,
        year: this.filteryear,
        month: this.filterMonth,
        organisme: this.filterOrganisme
      });
    }
  },
  computed: {
    ...mapGetters([
      'getChargementSuiviPaiementTh',
      'getTotalRowSuiviPaiementTh',
      'getSuiviPaiementThTable',
      'getErrorSuiviPaiementTh',
      'cantExport',
      'cantUpdate',
      'getMonthForYearTh',
      'getOrganismeTh',
      'getcomputedDepots'
    ]),
    computedGetOrganismeTh() {
      const tab = this.getOrganismeTh.map(item => ({
        value: item.name,
        text: item.name
      }));
      return tab;
    },
    computedCantUpdate() {
      return this.cantUpdate(this.$options.name);
    },
    ComputedgetAllEmployee() {
      return [{ id: null, full_name: 'tous' }, ...this.getActiveEmployyes];
    },
    computedgetuserSalary() {
      return this.getSuiviPaiementThTable;
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    }
  },
  components: {
    EditableInput,
    EditableInputSuiviTh
  },
  async mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;
    if (localStorage.getItem('suivi-paiement-obligee')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('suivi-paiement-obligee')
      ).year;
    }
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;
    if (localStorage.getItem('suivi-paiement-obligee')) {
      this.filterMonth = JSON.parse(
        localStorage.getItem('suivi-paiement-obligee')
      ).month;
      this.filterDepot = JSON.parse(
        localStorage.getItem('suivi-paiement-obligee')
      ).depot;
      this.filterOrganisme = JSON.parse(
        localStorage.getItem('suivi-paiement-obligee')
      ).organisme;
    } else {
      this.filteryear = courantYear;
      this.filterMonth = courantMonth;
      this.setLocalStorageSuiviPaiementobligee();
    }
    if (sessionStorage.getItem('page-suivi-paiement-obligee')) {
      this.page = sessionStorage.getItem('page-suivi-paiement-obligee');
    } else {
      sessionStorage.setItem('page-suivi-paiement-obligee', this.page);
    }

    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.fetchAllOrganismeTh();
    this.featchDepotForYearTH(this.filteryear);
    this.handleFilter();
  }
};
</script>

<style scoped lang="scss">
.tableSuiviPaiementTh {
  height: calc(100vh - 220px);
  max-height: calc(100vh - 151px);
  margin-bottom: 0px;
}
.body-Suivi-paiement-th {
  margin-top: 5px;
  height: calc(100vh - 114px);
}
.sub-menu .body .body-Suivi-paiement-th {
  height: calc(100vh - 175px);
}

.Suivi-paiement-th {
  margin: 0px;
  width: 100%;
  padding: 10px;
  display: block;
  height: calc(100vh - 60px);
  overflow: hidden;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
.ModelCol {
  justify-content: space-between;
  .form-group {
    width: 58%;
  }
}
</style>
<style lang="scss">
.tableSuiviPaiementTh {
  td,
  th {
    white-space: nowrap !important;
    width: auto !important;
    min-width: auto;
    padding: 6px 5px !important;
  }
  th {
    font-size: 9.5px !important;
  }
  td {
    font-size: 10px;
  }
  .th-1-bg-color {
    background-color: #b4b2e6 !important;
    color: white !important;
  }
  .th-2-bg-color {
    background-color: #dfdef4 !important;
  }
  .th-3-bg-color {
    background-color: #f0f1ff !important;
  }
  .th-4-bg-color {
    background-color: #cccbe4 !important;
  }

  .th-border-raduis-left {
    border-radius: 10px 0px 0px 0px;
  }
  .th-border-raduis-rigth {
    border-radius: 0px 10px 0px 0px;
  }
}
</style>
